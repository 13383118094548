import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserProvider } from "./contexts/userContext";
import "./lang/i18n";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const History = lazy(() => import("./pages/History"));
const Message = lazy(() => import("./pages/Message"));
const Topup = lazy(() => import("./pages/Topup"));
const Other = lazy(() => import("./pages/Other"));
const Map = lazy(() => import("./pages/Map"));
const Promotion = lazy(() => import("./pages/Promotion"));
const PromotionCollect = lazy(() => import("./pages/PromotionCollect"));
const Profile = lazy(() => import("./pages/Profile"));
const Machinelist = lazy(() => import("./pages/Machinelist"));
const Reward = lazy(() => import("./pages/Reward"));
const HistoryReward = lazy(() => import("./pages/HistoryReward"));
const Login = lazy(() => import("./pages/Login"));
const LoginSansiri = lazy(() => import("./pages/Login-sansiri"));
const Register = lazy(() => import("./pages/Register"));
const Loading = lazy(() => import("./pages/Loading"));
const Setting = lazy(() => import("./pages/Setting"));
const Policy = lazy(() => import("./pages/Policy"));
const DeleteUser = lazy(() => import("./pages/DeleteUser"));
const Forgot = lazy(() => import("./pages/Forgot"));
const HomeV2 = lazy(() => import("./pages/Home-v2"));
const HomeSansiri = lazy(() => import("./pages/Home-sansiri"));
const MachineListV2 = lazy(() => import("./pages/Machinelist-v2"));
const MachinelistSansiri = lazy(() => import("./pages/Machinelist-Sansiri"));
const App = () => (
  <UserProvider>
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/home/v2" component={HomeV2} />
          <Route exact path="/home/Sansiri" component={HomeSansiri} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/history" component={History} />
          <Route exact path="/message" component={Message} />
          <Route exact path="/topup" component={Topup} />
          <Route exact path="/other" component={Other} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/promotion" component={Promotion} />
          <Route exact path="/promotionCollect" component={PromotionCollect} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/machinelist/:id" component={Machinelist} />
          <Route exact path="/reward" component={Reward} />
          <Route exact path="/history/reward" component={HistoryReward} />
          <Route exact path="/loading/:id" component={Loading} />
          <Route exact path="/setting" component={Setting} />

          <Route
            exact
            path="/machinelist/v2/:id/:site_code"
            component={MachineListV2}
          />
          <Route
            exact
            path="/machinesansiri/v2/:id/:site_code/:customerId"
            component={MachinelistSansiri}
          />
          
        </Switch>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={Login} />
        </Switch>
        <Switch>
          <Route exact path="/sansiri" component={LoginSansiri} />
        </Switch>
        <Switch>
          <Route exact path="/register" component={Register} />
        </Switch>
        <Switch>
          <Route exact path="/forgot" component={Forgot} />
        </Switch>
        
        <Switch>
          <Route exact path="/policy" component={Policy} />
        </Switch>
        <Switch>
          <Route exact path="/delete/user" component={DeleteUser} />
        </Switch>
      </Suspense>
    </Router>
  </UserProvider>
);

export default App;
